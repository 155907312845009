<script setup>
import {useLocalePath} from 'vue-i18n-routing'

const props = defineProps({
  modal: {
    default: 'modal:project',
    type: String,
  },
  link: {
    type: String,
  },
  title: {
    // default: 'Contact us for a project',
    type: String,
  },
})

const {$event, $i18n} = useNuxtApp()
const localePath = useLocalePath()

// Managing default title translations
let { title } = toRefs(props)
if( !title.value ) title = $i18n.t('floating_button.contact_project')

const openProperModal = () => {
  $event(props.modal)
}

const isHidden = ref(true)
let footerOffset = 100

const handleScroll = () => {
  const heroOffset = 50

  const scrollTop = window.scrollY || document.documentElement.scrollTop
  const windowHeight = window.innerHeight
  const docHeight = document.documentElement.offsetHeight
  const scrollBottom = docHeight - windowHeight - scrollTop

  // show button when
  // - not in hero
  // - not in footer
  if (scrollTop > heroOffset || scrollBottom > footerOffset) {
    isHidden.value = false
  }

  // hide button when
  // - in hero
  // - in footer
  if (scrollTop <= heroOffset || scrollBottom <= footerOffset) {
    isHidden.value = true
  }
}

const goToPage = (url) => {
  const localizedPath = localePath(url)
  console.log('localizedPath', localizedPath)

  if( !localizedPath ){
    return navigateTo(url, {
            external: true,
            open: {
              target: '_blank',
            }
          })
  } else{
    return navigateTo(localePath(url))
  }
}

// Aggiungi l'event listener per lo scroll quando il componente viene montato
onMounted(() => {
  window.addEventListener('scroll', handleScroll)

  if (document) {
    const footerCtas = document.querySelector('.footer-ctas')
    const footer = document.querySelector('.footer')

    footerOffset = 50

    if (footerCtas) {
      footerOffset += footerCtas.offsetHeight
    }

    if (footer) {
      footerOffset += footer.offsetHeight
    }
  }
})

// Rimuovi l'event listener quando il componente viene smontato
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <button
    v-if="props.modal"
    id="contact-button-side"
    :class="{'is-hidden': isHidden}"
    class="contact-button-follow btn btn--primary btn--primary-full sensible"
    @click="openProperModal">
    {{ title }}
    <div class="icon-container">
      <svg class="icon icon--rotate-315 icon--btn-round-small">
        <use xlink:href="#icon-arrow"></use>
      </svg>
    </div>
  </button>
  <button
    v-else-if="props.link"
    id="contact-button-side"
    :class="{'is-hidden': isHidden}"
    class="contact-button-follow btn btn--primary btn--primary-full sensible"
    @click="goToPage(props.link)">
    {{ title }}
    <div class="icon-container">
      <svg class="icon icon--rotate-315 icon--btn-round-small">
        <use xlink:href="#icon-arrow"></use>
      </svg>
    </div>
  </button>
</template>

<style lang="scss">
.contact-button-follow {
  align-items: center;
  bottom: pxrem(45);
  display: flex;
  justify-content: center;
  letter-spacing: 0 !important;
  position: fixed;
  right: 50%;
  transform: translateX(50%);
  text-align: center;
  text-transform: none !important;
  transition: all $transition-slow !important;
  width: 80%;
  z-index: 1;

  @include mq(lg) {
    right: col-width(0.9);
    transform: translateX(0);
    width: auto;
  }

  &:hover {
    border-color: $color-blue !important;
    .icon-container {
      border-color: $color-blue;

      svg {
        @include icon-color($color: $color-blue);
      }
    }
  }

  .icon-container {
    border: 1px solid $color-white;
    border-radius: 50%;
    display: inline-block;
    margin-left: pxrem(8);
    padding: pxrem(3);
  }

  svg {
    @include icon-color($color: $color-white);
  }

  &.is-hidden {
    opacity: 0;
    transform: translateX(120%);
  }
}
</style>
